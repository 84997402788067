import React, { useState } from "react";
import Table from "./Structures/Table";
import { Link, useParams } from "react-router-dom";

const CompanyLobbyists = ({ lobbyists }) => {
  const [sortingField, setSortingField] = useState("LobbyistLastName");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const { state } = useParams();

  // Sort lobbyists based on sortingField and sortingDirection
  const sortLobbyists = (lobbyists) => {
    return [...lobbyists].sort((a, b) => {
      const valueA = a[sortingField];
      const valueB = b[sortingField];
      if (valueA < valueB) {
        return sortingDirection === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortingDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Handles changing the sorting field and direction
  const handleSort = (field) => {
    if (sortingField === field) {
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    } else {
      setSortingField(field);
      setSortingDirection("asc");
    }
  };

  const headers = [
    <span
      onClick={() => handleSort("LobbyistLastName")}
      className="cursor-pointer"
    >
      Lobbyist Name
    </span>,
    <span
      onClick={() => handleSort("LobbyistRegisteredBranches")}
      className="cursor-pointer"
    >
      Representation Dates
    </span>,
    <span
      onClick={() => handleSort("LobbyistPhone")}
      className="cursor-pointer"
    >
      Contact
    </span>,
    <span onClick={() => handleSort("Compensation")} className="cursor-pointer">
      Compensation
    </span>,
  ];

  const rows = sortLobbyists(lobbyists).map((lobbyist) => [
    <Link
      to={`/${state}/lobbyist/${lobbyist.FilerID}`}
      className="lobbyCompanyName"
    >
      {lobbyist.LobbyistTitle} {lobbyist.LobbyistFirstName}{" "}
      {lobbyist.LobbyistMiddleName} {lobbyist.LobbyistLastName}
    </Link>,
    <Link
      to={`/${state}/lobbyist/${lobbyist.FilerID}`}
      className="lobbyCompanyName"
    >
      {lobbyist.RepresentationDatesRegistered}
    </Link>,
    <Link
      to={`/${state}/lobbyist/${lobbyist.FilerID}`}
      className="lobbyCompanyName"
    >
      {lobbyist.LobbyistPhone}
    </Link>,
    <Link
      to={`/${state}/lobbyist/${lobbyist.FilerID}`}
      className="lobbyCompanyName"
    >
      {`$${lobbyist.PaymentRange.low.toLocaleString()} - $${lobbyist.PaymentRange.high.toLocaleString()}`}
    </Link>,
  ]);

  return (
    <div className="bg-[#F9FAFB] border border-[#E5E7EB] 2xl:p-8 xl:p-6 p-5">
      <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
        <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
          Lobbyist
        </h2>
      </div>
      {lobbyists && lobbyists.length > 0 ? (
        <Table headers={headers} rows={rows} />
      ) : (
        <p className="font-playfair text-[28px] text-darkblue font-medium leading-8">
          No lobbyists found for this company.
        </p>
      )}
    </div>
  );
};

export default CompanyLobbyists;
