import React, { useState } from "react";

const NewSearchBar = ({ data, fields, label, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilteredData, setShowFilteredData] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setShowFilteredData(value.length > 0);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const topResult = filteredData.length > 0 ? filteredData[0] : "";
      setSearchTerm(getValueFromItem(topResult));
      setShowFilteredData(false);
      onSelect(topResult);
    }
  };

  const getValueFromItem = (item) => {
    if (typeof item === "string") {
      return item;
    } else if (typeof item === "object" && fields) {
      return fields.map((field) => item[field]).join(" ");
    }
    return "";
  };

  const handleSelect = (item) => {
    const selectedValue = getValueFromItem(item);
    setSearchTerm(selectedValue);
    setShowFilteredData(false);
    onSelect(selectedValue);
  };

  const filteredData =
    searchTerm.length > 0
      ? data.filter((item) => {
          const itemValue = getValueFromItem(item).toLowerCase();
          return itemValue.includes(searchTerm);
        })
      : [];

  const handleBlur = () => {
    setTimeout(() => {
      setShowFilteredData(false);
    }, 150); // Delay hiding the dropdown
  };

  return (
    <div>
      <label>{label} Search:</label>
      <input
        type="text"
        placeholder={`Search ${label}...`}
        value={searchTerm}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      {showFilteredData && filteredData.length > 0 && (
        <div className="dataResult newData">
          <ul style={{ listStyleType: "none", padding: 0, marginTop: "10px" }}>
            {filteredData.slice(0, 4).map((item, index) => (
              <li
                key={index}
                onClick={(e) => {
                  handleSelect(item);
                }}
                style={{
                  cursor: "pointer",
                  padding: "5px 10px",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <span className="dataItem">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewSearchBar;
