const statusCodes = {
  FIL: "Filed",
  HCS: "House Committee Substitute",
  HPA: "House Proposed Amendment",
  HFA: "House Floor Amendment",
  ENG: "Engrossed",
  SCS: "Senate Committee Substitute",
  SPA: "Senate Proposed Amendment",
  SFA: "Senate Floor Amendment",
  ENR: "Enrolled",
  CCR: "Concurrent Resolution",
  VET: "Governor's Veto Message",
};

export default statusCodes;
